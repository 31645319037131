import { mapActions } from 'vuex'

export default{
    
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    model: {},
    show: false,
    showForm: false,
    colors: ['#66BB6A', '#81D4FA', '#0277BD', '#FFEB3B', '#FF5722'],
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    dialogFilter: false,
    byPreview: false,
    payloadFilter: null,
    headers: [
        { text: 'Campus', value: 'campus_name', sortable: false},
        { text: 'Turno', value: 'shift_name', sortable: false},
        { text: 'Turma', value: 'group_name', sortable: false},
        { text: 'Disciplina', value: 'discipline_name', sortable: false},
        { text: 'Dia', value: 'day_of_week_name', sortable: false},
        { text: 'Ambiente', value: 'ambience_name', sortable: false},
        { text: 'Capacidade', value: 'ambience_capacity', align: 'center', sortable: false},
        { text: 'Alunos', value: 'students',  align: 'center', sortable: false},
        { text: 'Previsão', value: 'students_forecast',  align: 'center', sortable: false},
        { text: 'Saldo', value: 'balance_students',  align: 'center', sortable: false},
        { text: 'Saldo', value: 'balance_students_forecast',  align: 'center', sortable: false},
        { text: 'Ocupação', value: 'occupation_students', align: 'center', sortable: false},
        { text: 'Ocupação', value: 'occupation_students_forecast', align: 'center', sortable: false},
        { text: 'Junção', value: 'join', align: 'center', sortable: false},
        { text: 'Ações', value: 'actions', hide: true, align: 'center', sortable: false},
    ]
    }),
    
    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item " + item_new.name + "?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
    },
    computed: {
        
        filteredHeaders(){

            this.headers[7].hide = this.byPreview;
            this.headers[9].hide = this.byPreview;
            this.headers[11].hide = this.byPreview;

            this.headers[8].hide = !this.byPreview;
            this.headers[10].hide = !this.byPreview;
            this.headers[12].hide = !this.byPreview;
            
            return this.headers.filter(h => !h.hide);

        }

    },
   
    methods: {
      ...mapActions('roomAllocation', ['ActionIndexVacancyControl']),
        
      getData(){
      
        this.error = false;
        this.message = '';
        this.loadingProgress = true;
        this.showForm = false;

        var payload = this.params;

        payload.filter = this.payloadFilter;

        if(payload.filter){
          payload.filter.byPreview = this.byPreview;
        }

        this.ActionIndexVacancyControl(payload)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
                
            })
    },

    getColor(value){

        if(value <= -10){
          return this.colors[4];
        }else if(value < 0){
          return this.colors[3];
        }else if(value == 0){
          return this.colors[2];
        }else if(value <= 10){
          return this.colors[1];
        }else{
          return this.colors[0];
        }

    },

    filter(payload){
        this.payloadFilter = payload;
        this.dialogFilter = false;
        this.getData();
    },

  },

}
