var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-container',{attrs:{"icon":_vm.$route.meta.icon,"title":_vm.$route.meta.title},scopedSlots:_vm._u([{key:"tools",fn:function(){return undefined},proxy:true}])},[_c('emc-loader-progress',{attrs:{"show":_vm.loadingProgress}}),_c('sys-ambience-filter-vacancy-control',{attrs:{"show":_vm.dialogFilter},on:{"update:show":function($event){_vm.dialogFilter=$event},"filter":function($event){return _vm.filter($event)}}}),_c('emc-table-items',{attrs:{"headers":_vm.filteredHeaders,"params":_vm.params,"collections":_vm.collections,"meta":_vm.meta,"itemDelete":_vm.itemDelete,"itemEdit":_vm.model,"loading":_vm.loading,"find":"","show-filter":"","hideEdit":"","showDelete":false,"show-append":"","payload-filter":_vm.payloadFilter},on:{"update:params":function($event){_vm.params=$event},"update:collections":function($event){_vm.collections=$event},"update:meta":function($event){_vm.meta=$event},"update:itemDelete":function($event){_vm.itemDelete=$event},"update:item-delete":function($event){_vm.itemDelete=$event},"update:itemEdit":function($event){_vm.model=$event},"update:item-edit":function($event){_vm.model=$event},"click-filter":function($event){_vm.dialogFilter = true}},scopedSlots:_vm._u([{key:"item.discipline_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discipline_name)+" "),_c('small',[_c('br'),_vm._v(_vm._s(item.work_load_type_initials)+" "),(item.group_number)?_c('span',[_vm._v(" | D"+_vm._s(item.group_number))]):_vm._e(),_vm._v(" "),(item.teacher_number)?_c('span',[_vm._v(" | P"+_vm._s(item.teacher_number))]):_vm._e()])]}},{key:"item.students",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.join_id ? item.join_students : item.students)+" "),(item.join_id)?_c('small',[_c('br'),_vm._v(_vm._s(item.students)+" ")]):_vm._e()]}},{key:"item.students_forecast",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.join_id ? item.join_students_forecast : item.students_forecast)+" "),(item.join_id)?_c('small',[_c('br'),_vm._v(_vm._s(item.students_forecast)+" ")]):_vm._e()]}},{key:"item.balance_students",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"justify-center",staticStyle:{"width":"60px"},attrs:{"color":_vm.getColor(item.balance_students),"dark":""}},[_vm._v(" "+_vm._s(item.balance_students)+" ")])]}},{key:"item.balance_students_forecast",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"justify-center",staticStyle:{"width":"60px"},attrs:{"color":_vm.getColor(item.balance_students_forecast),"dark":""}},[_vm._v(" "+_vm._s(item.balance_students_forecast)+" ")])]}},{key:"item.occupation_students",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"height":"25","color":"light-blue","striped":"","value":item.ambience_capacity ? (item.join_id ? item.join_students : item.students / item.ambience_capacity) * 100 : 0}},[(item.ambience_capacity && item.join_id)?_c('strong',[_vm._v(_vm._s(Math.ceil( (item.join_students / item.ambience_capacity) * 100 ))+"%")]):_vm._e(),(item.ambience_capacity && !item.join_id)?_c('strong',[_vm._v(_vm._s(Math.ceil( (item.students / item.ambience_capacity) * 100 ))+"%")]):_vm._e()])]}},{key:"item.occupation_students_forecast",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"height":"25","color":"light-blue","striped":"","value":item.ambience_capacity ? (item.join_id ? item.join_students_forecast : item.students_forecast / item.ambience_capacity) * 100 : 0}},[(item.ambience_capacity && item.join_id)?_c('strong',[_vm._v(_vm._s(Math.ceil( (item.join_students_forecast / item.ambience_capacity) * 100 ))+"%")]):(item.ambience_capacity && !item.join_id)?_c('strong',[_vm._v(_vm._s(Math.ceil( (item.students_forecast / item.ambience_capacity) * 100 ))+"%")]):_vm._e()])]}},{key:"item.join",fn:function(ref){
var item = ref.item;
return [(item.join_id)?_c('span',[_vm._v(" "+_vm._s(item.join_id)+" "),(item.grid_main)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"yellow"}},'v-icon',attrs,false),on),[_vm._v("mdi-star-half-full")])]}}],null,true)},[_c('span',[_vm._v("Turma Principal")])]):_vm._e()],1):_vm._e()]}},{key:"footer.prepend",fn:function(){return [_c('v-switch',{staticClass:"ml-3",attrs:{"inset":"","label":"Por previsão de alunos"},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.byPreview),callback:function ($$v) {_vm.byPreview=$$v},expression:"byPreview"}})]},proxy:true}])}),_c('emc-alert-modal',{attrs:{"text":_vm.textDelete,"disabled":_vm.deleting,"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","loading":_vm.deleting,"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("Excluir "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-delete")])],1)]},proxy:true}])}),_c('emc-alert-snackbar',{attrs:{"show":!! _vm.message.length,"message":_vm.message,"color":_vm.error ? 'error' : 'success'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }